import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { auth, db } from '../firebase-config';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import '../scss/energyStatistics.scss';
import ProfileNavBar from './profilenavbar';
import SpeedometerGauge from './speedometer';
import ModeSelector from './buy@sell';
import { useDevice } from '../context/deviceContext';
import KWhGraph from './theGraph';
import EarningsGraph from './earningsGraph';

function Dashboard() {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const { deviceStatus, setDeviceStatus, updateDeviceID, fetchDeviceStatus, toggleDeviceSwitch } = useDevice();
    const navigate = useNavigate();
    const [homeAssistantBattery, setHomeAssistantBattery] = useState('');
    const [userId, setUserId] = useState('');
    const [mode, setMode] = useState('off');
    const [resetTFE, setResetTFE] = useState(false);
    const [totalBuyerWatts, setTotalBuyerWatts] = useState(0);

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchUserData = async () => {
            const user = auth.currentUser;
            if (user) {
                setUserId(user.uid);
                const userDocRef = doc(db, 'users', user.uid);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    updateDeviceID(userData.deviceID);
                    setMode(userData.mode || 'off');
                } else {
                    console.log('No user document found');
                    navigate('/');
                }
            }
        };
        fetchUserData();
    }, [navigate, updateDeviceID]);

    // Poll the device status periodically and fetch buyer watts if in sell mode
    useEffect(() => {
        const fetchBuyerWatts = async () => {
            if (mode === 'sell') {
                try {
                    const response = await axios.post('https://us-central1-watt-street.cloudfunctions.net/fetchPhaseAPowerData', {
                        userId: userId
                    });
                    const wattsArray = Object.values(response.data).map(parseFloat);
                    const totalWatts = wattsArray.reduce((acc, watts) => acc + watts, 0);
                    setTotalBuyerWatts(totalWatts);
                } catch (error) {
                    console.error('Error fetching buyer watts:', error);
                }
            }
        };

        if (!resetTFE) {
            fetchDeviceStatus();
            if (mode === 'sell') {
                fetchBuyerWatts();
            }
        }

        const interval = setInterval(() => {
            if (!resetTFE) {
                fetchDeviceStatus();
                if (mode === 'sell') {
                    fetchBuyerWatts();
                }
            }
        }, 5000);
        return () => clearInterval(interval);
    }, [fetchDeviceStatus, resetTFE, mode, userId]);

    const handleResetTFE = async () => {
        const userRef = doc(db, 'users', auth.currentUser?.uid);
        try {
            const currentTFE = parseFloat(deviceStatus.totalForwardEnergy);
            await updateDoc(userRef, {
                tfeResetOffset: currentTFE
            });
            setDeviceStatus(prevState => ({
                ...prevState,
                totalForwardEnergy: '0 kWh'
            }));
            console.log("Total Forward Energy reset successfully!");
        } catch (error) {
            console.error("Failed to reset Total Forward Energy:", error);
        }
    };

    return (
        <div className='dashcontainer'>
            <ProfileNavBar userId={userId} />
            <div className='card'>
                <ModeSelector 
                    toggleMode={(newMode) => {
                        const userRef = doc(db, 'users', auth.currentUser?.uid);
                        updateDoc(userRef, { mode: newMode }).then(() => {
                            console.log("Mode updated successfully!");
                            setMode(newMode);
                            setResetTFE(false);
                        }).catch(error => console.error("Failed to update mode:", error));
                    }} 
                    deviceStatus={deviceStatus}
                    toggleDeviceSwitch={toggleDeviceSwitch}
                />

                <div className='measurements-container'>
                    <div className="measurement-box">
                        <SpeedometerGauge 
                            currentWatts={mode === 'sell' ? totalBuyerWatts * 1000 : parseFloat(deviceStatus.kW)} 
                            maxWatts={deviceStatus.nominalVoltage * deviceStatus.maxChargeCurrent / 1000} 
                            mode={mode} 
                        />
                    </div>

                    <div className="measurement-box">
                        <span>Your Energy Total!</span>
                        <div className="graph-bar">
                            <div className="graph-value" style={{ width: `${parseFloat(deviceStatus.totalForwardEnergy) / deviceStatus.totalCapacity * 100}%` }}></div>
                        </div>
                        <span>used {deviceStatus.totalForwardEnergy} kWh from neighbours!</span>
                        <button onClick={handleResetTFE}>Reset</button>
                    </div>
                </div>

                <div className="measurement-box">
                    <KWhGraph />
                    <EarningsGraph/>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
